@tailwind base;
@tailwind components;
@tailwind utilities;

/*div {*/
/*	border: 1px solid #000000;*/
/*}*/


@layer base {
    :root {
        /* light theme */
        /* --font-sans: here if you have one */
        /* --font-mono: here if you got it... */

        /* prefixed with foreground because it should look good on the background */
        --foreground-destructive: 345 82.7% 40.8%;

        --background: 0 0% 88%;
        --foreground: 222.2 84% 4.9%;

        --muted: 210 40% 93%;
        --muted-foreground: 215.4 16.3% 30%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 210 40% 96%;

        --border: 214.3 31.8% 91.4%;
        /*--input: 214.3 31.8% 91.4%;*/
        --input: 0 31.8% 91.4%;
        --input-invalid: 0 84.2% 60.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 20% 83%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 90%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 70% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;


    }

    .dark {
        --background: 205 37% 12%;
        --foreground: 210 40% 98%;

        /* prefixed with foreground because it should look good on the background */
        --foreground-destructive: -4 84% 60%;

        --muted: 217.2 32.6% 12%;
        --muted-foreground: 215 20.2% 65.1%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --card: 0 0% 100%;
        --card-foreground: 210 40% 96%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --input-invalid: 0 62.8% 30.6%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 20% 24%;
        --secondary-foreground: 210 40% 98%;

        --accent: 217.2 32.6% 10%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 60% 40%;
        --destructive-foreground: 0 85.7% 97.3%;

        --ring: 217.2 32.6% 60%;
    }
}


/*.swiper-pagination {*/
/*    bottom: -14px !important;*/
/*    position: relative;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    gap: 16px;*/
/*}*/

.swiper-pagination-fraction {
    font-weight: bold;
}

/***************************/
/*     17. Extra Pages     */
/***************************/
.ex-header {
    padding-top: 8rem;
    padding-bottom: 5rem;
    text-align: center;
}

.ex-basic-1 {
    padding-top: 2rem;
    padding-bottom: 0.875rem;
}

.ex-basic-1 .breadcrumbs {
    margin-bottom: 1.125rem;
}

.ex-basic-1 .breadcrumbs .fa {
    margin-right: 0.5rem;
    margin-left: 0.625rem;
}

.ex-basic-2 {
    padding-top: 4.75rem;
    padding-bottom: 4rem;
}

.ex-basic-2 h3 {
    margin-bottom: 1rem;
}

.ex-basic-2 .text-container {
    margin-bottom: 3.625rem;
}

.ex-basic-2 .text-container.last {
    margin-bottom: 0;
}

.ex-basic-2 .list-unstyled .fas {
    color: #00c9db;
    font-size: 0.5rem;
    line-height: 1.375rem;
}

.ex-basic-2 .list-unstyled .media-body {
    margin-left: 0.625rem;
}

.ex-basic-2 .btn-outline-reg {
    margin-top: 1.75rem;
}

.ex-basic-2 .image-container-large {
    margin-bottom: 4rem;
}

.ex-basic-2 .image-container-large img {
    border-radius: 0.25rem;
}

.ex-basic-2 .image-container-small img {
    border-radius: 0.25rem;
}

.ex-basic-2 .text-container.dark-bg {
    padding: 1.625rem 1.5rem 0.75rem 2rem;
}

/*a {*/
/*    text-decoration-line: underline;*/
/*    color: #2563eb;*/
/*}*/



.tiptap p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}
